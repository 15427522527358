
<template>
<div>
    <div class="row">
      <div class="col-12">
        <div class="card">
            <div class="card-body">
                <div class="row mb-2">
                    <div class="col-sm-12 col-md-6">
                        <div id="tickets-table_length" class="dataTables_length">
                            <label class="d-inline-flex align-items-center">
                                <span class="mr-1">Display</span>
                                <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>
                            </label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                      <div class="form-inline float-sm-right">
                        <div id="tickets-table_filter" class="dataTables_filter mr-sm-2 text-md-right">
                            <label class="d-inline-flex mb-0 align-items-center">
                                <span class="mr-2 d-none d-sm-inline">Search:</span>
                                <b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm"></b-form-input>
                            </label>
                        </div>
                        <b-dropdown variant="light" size="sm" right>
                          <template slot="button-content">
                              <i class="mdi mdi-filter-variant"></i>
                          </template>
                          <b-dropdown-item-button @click="selectAllRows()"><i :class="isSelectAll? 'mdi mdi-playlist-remove' : 'mdi mdi-playlist-check'" ></i> {{isSelectAll? 'DeSelect All' : 'Select All' }}</b-dropdown-item-button>
                        </b-dropdown>
                      </div>
                    </div>
                </div>
                <div class="table-responsive mb-0">
                  <b-table striped hover table-class="table font-sm table-centered w-100" thead-tr-class="" ref="selectableTable" :items="payments" :fields="columns" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template v-slot:cell(check)="data">
                        <div class="custom-control custom-checkbox text-center">
                            <input v-model="selected" type="checkbox" :value="data.item.id" class="custom-control-input" :id="`checkorder${data.item.id}`" />
                            <label class="custom-control-label" :for="`checkorder${data.item.id}`">{{data.item.id}}</label>
                        </div>
                    </template>
                    <template v-slot:cell(payer)="data">
                        <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                            <span class="text-dark font-sm">{{ data.item.payer.first_name }}</span>
                        </h5>
                    </template>
                    <template v-slot:cell(reference)="data">

                      <h5 :id="'pay-dec-'+data.item.id" class="m-0 d-inline-block cursor-pointer align-middle">
                        <span class="text-dark d-block font-sm">PID: {{ data.item.paymentid }}</span>
                        <span class="text-dark mt-1 d-block font-sm">TF: {{ data.item.transref }}</span>
                      </h5>
                      <b-tooltip :target="'pay-dec-'+data.item.id">
                        {{ data.item.desc }}
                      </b-tooltip>
                      <!-- <b-button  variant="light">Tooltip on top</b-button> -->
                    </template>

                    <template v-slot:cell(paid_amount)="data">
                        <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                            <span class="text-dark font-sm">${{ data.item.paid_amount }}</span>
                        </h5>
                    </template>
                    <template v-slot:cell(payvia)="data">
                        <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                            <span class="text-success d-block font-sm">{{ data.item.payvia }}</span>
                            <span class="text-dark d-block" style="font-size: 10px;">{{ data.item.method }}</span>
                        </h5>
                    </template>
                    <template v-slot:cell(currency)="data">
                      <h5 class="m-0 d-inline-block cursor-pointer align-middle">
                        <span class="text-dark font-sm">{{ data.item.currency }}</span>
                      </h5>
                    </template>
                    <template v-slot:cell(date)="data">
                      <span class="cursor-pointer">{{data.item.created_at | date_ago}} </span>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>
</template>

<script>
import IsLoading from "@/components/IsLoading.vue"

export default{
  components:{
    IsLoading
  },
  data() {
    return {
      isloading: false,
      payments: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      selected: [],
      isSelectAll: false,
      columns: [
          {
              key: "check",
              label: "ID"
          },
          {
            key: "payer",
            sortable: true
          },
          {
            key: "reference",
            label: "References",
            sortable: true
          },
          {
            key: "paid_amount",
            label: "Amount",
            sortable: true
          },
          {
            key: "payvia",
            label: "Pay Via",
            sortable: true
          },
          {
            key: "currency",
            sortable: true
          },
          {
              key: "date",
              label: "Date",
              sortable: true
          },
      ]
    }
  },
  computed: {
    rows() {
      return this.payments.length;
    },
  },
  methods: {
    selectAllRows() {
      this.isSelectAll = !this.isSelectAll
      if(this.isSelectAll){
        this.selected = this.payments.map(o => o.id );
      }else{
        this.selected = []
      }
    },
    fetchPayments() {
      this.isLoading = true
      this.$http.get(`/payments`)
      .then((response) => {
        this.isLoading = false
        if(response.data.success){
          this.payments = response.data.data.payments;
        }
      })
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  mounted() {
    this.totalRows = this.payments.length;
  },
  created(){
    this.fetchPayments()
  },
}
</script>
